/**
 * Snippet registers events on form elements
 */
const formInputEvents = () => {
  const classes = {
    focus: 'is-focused',
    submitting: 'is-form-submitting',
  };

  function toggleFocus() {
    const $input = $(this);
    const $inputWrapper = $input.parent();

    if ($inputWrapper.hasClass(classes.focus) && $input.val()) {
      return;
    }

    // wait until datepicker adds value to the input, then remove class if the value wasn't added
    if ($inputWrapper.hasClass(classes.focus)) {
      setTimeout(() => {
        if (!$input.val()) {
          $inputWrapper.removeClass(classes.focus);
        }
      }, 75);
    } else {
      $inputWrapper.addClass(classes.focus);
    }
  }

  const inputEvents = {
    focus: toggleFocus,
    blur: toggleFocus,
    'select2:open': toggleFocus,
    'select2:close': toggleFocus,
  };

  function onFormSubmit() {
    const $form = $(this);
    $form.addClass(classes.submitting);
  }

  const formEvents = {
    submit: onFormSubmit,
  };

  const addEventListeners = (nodeName, eventsMap) => {
    $(nodeName).each((index, item) => {
      const $item = $(item);

      Object.keys(eventsMap).forEach((eventName) => {
        $item.on(eventName, eventsMap[eventName]);
      });
    });
  };

  addEventListeners('input, textarea, select', inputEvents);
  addEventListeners('form', formEvents);
};

export default formInputEvents;
