/* eslint-disable no-confusing-arrow */
/**
 * Return target parent if exists
 *
 * @param {Array<Element>} $target
 * @param {string} selector
 *
 * @returns {Element|undefined}
 */
const findParent = ($target, selector) => $target.parents(selector).length ? $target.parents(selector) : $target;

export default findParent;
