/**
 * Snippet checks if given element is in view
 *
 * @param {node} $element - an element to check against
 * @param {number} offset - offset by how much pixels is element being visible
 */
const isInViewTop = ($element, offset = 150) => {
  const currentTop = $element.offset().top;
  const distanceFromTop = $(window).scrollTop();

  return distanceFromTop + offset >= currentTop;
};

const isInView = ($element) => {
  const elementTop = $element.offset().top;
  const elementBottom = elementTop + $element.outerHeight();
  const viewportTop = $(window).scrollTop();
  const viewportBottom = viewportTop + $(window).height();
  return elementBottom > viewportTop && elementTop < viewportBottom;
};

export { isInViewTop, isInView };
