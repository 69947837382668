import datepicker from 'js-datepicker/dist/datepicker.min';

/**
 * Datepicker initializes on input with {datepicker} class
 * Provides custom events bound to input
 * {onSelect}
 * {onShow}
 * {onMonthChange}
 * {onHide}
 */
const inputs = document.querySelectorAll('.datepicker');

const initDatepicker = (input) => {
  const datepickerCustomEvent = (eventName, detail) => {
    input.dispatchEvent(new CustomEvent(
      eventName,
      {
        bubbles: true,
        cancelable: true,
        detail,
      },
    ));
  };

  const settings = {
    onSelect: (instance, date) => datepickerCustomEvent('onSelect', { instance, date }),
    onShow: (instance) => datepickerCustomEvent('onShow', { instance }),
    onMonthChange: (instance) => datepickerCustomEvent('onMonthChange', { instance }),
    onHide: (instance) => datepickerCustomEvent('onHide', { instance }),
  };

  datepicker(input, settings);
};

const init = () => {
  Array.from(inputs).forEach((input) => initDatepicker(input));
};

export default init;
