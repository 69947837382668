import stickyElement from '../snippet/sticky-element';
import testElementsExistence from '../snippet/test-elements-existence';
import { isInViewTop } from '../snippet/is-in-view';

/**
 * Context nav as a floating sidebar widget
 *
 * {context-nav-wrapper} - main container class
 * {context-nav} - item that holds context nav
 * {context-nav-toggle} - clickable element that triggers context nav on mobile
 * {context-nav-menu} - container that holds context navigation
 */

const mainClass = 'context-nav';

const init = ($container) => {
  const classes = {
    wrapper: `${mainClass}-wrapper`,
    nav: `${mainClass}`,
    toggle: `${mainClass}-toggle`,
    menu: `${mainClass}-menu`,
    open: `${mainClass}-open`,
    activeItem: 'is-active',
  };

  const selectors = {
    wrapper: `.${classes.wrapper}`,
    nav: `.${classes.nav}`,
    toggle: `.${classes.toggle}`,
    menu: `.${classes.menu}`,
  };

  const stickyContextNavBreakpoint = 1024;
  const stickyContextConfig = {
    offset_top: 0,
    parent: selectors.wrapper,
  };

  const $contextNav = $container.find(selectors.nav);
  const $contextNavItems = $contextNav.find(selectors.menu).find('a');
  const $contextNodes = $container.find('[id]');

  const sticky = stickyElement(stickyContextConfig);

  const toggleContextVisibility = () => {
    if ($contextNav.hasClass(classes.open)) {
      $contextNav.removeClass(classes.open);
      return;
    }

    $contextNav.addClass(classes.open);
  };

  const manageContextMenuVisibility = () => {
    const $toggle = $contextNav.find(selectors.toggle);
    $toggle.on('click', toggleContextVisibility);
  };

  const handleNavElementClickEvents = (e) => {
    const $target = $(e.target);

    if ($target.is('a')) {
      toggleContextVisibility();
    }
  };

  const markActiveElementInContextNav = (id) => {
    $contextNavItems.removeClass(classes.activeItem);
    $contextNavItems.filter((index, item) => item.hash === `#${id}`).addClass(classes.activeItem);
  };

  const resolveActiveElementInView = () => {
    const $nodeInView = $contextNodes.filter((index, node) => isInViewTop($(node))).last();

    if ($nodeInView.length) {
      markActiveElementInContextNav($nodeInView.attr('id'));
    }
  };

  const removeStickyBehaviour = () => {
    sticky.unstick($contextNav);
  };

  const addStickyBehaviour = () => {
    sticky.stick($contextNav);
  };

  const handleContextNavBehaviour = (e) => {
    manageContextMenuVisibility();
    if (e.currentTarget.innerWidth >= stickyContextNavBreakpoint) {
      addStickyBehaviour();
      return;
    }

    removeStickyBehaviour();
  };

  const addEventListeners = () => {
    const $window = $(window);
    $window.on('load resize orientationchange', (e) => handleContextNavBehaviour(e));
    $window.on('resize scroll', resolveActiveElementInView);
    $contextNav.on('click', (e) => handleNavElementClickEvents(e));
  };

  const checkSetupValidity = () => {
    const selectorsNotFound = testElementsExistence($container[0], [
      selectors.nav,
      selectors.toggle,
      selectors.menu,
    ]);

    if (selectorsNotFound) {
      /* eslint-disable no-console */
      console.warn(`Please add following selectors to make the context nav component work: ${selectorsNotFound}`);
      return;
    }

    addEventListeners();
    resolveActiveElementInView();
  };

  checkSetupValidity();
};

const contextNav = () => {
  $(`.${mainClass}-wrapper`).each((index, container) => init($(container)));
};

export default contextNav;
