const searchbarToggler = () => {
  const init = ($button) => {
    $button.on('click', () => {
      $(document.body).addClass('search-bar-open');
      const timeout = setTimeout(() => {
        $('.search-bar').find('input').focus();
        clearTimeout(timeout);
      }, 250);
    });
  };

  $('.js--searchbar-toggle').each((index, button) => init($(button)));
};

export default searchbarToggler;
