const scrollToDirection = () => {
  const directions = {
    end: 'end',
    top: 'top',
  };

  const scrollTo = (offset) => {
    window.scroll({
      top: Math.abs(offset),
      behavior: 'smooth',
    });
  };

  const handleScroll = (target) => {
    const direction = target.dataset.scrollTo;

    if (!directions[direction]) {
      /* eslint-disable no-console */
      console.warn('Invalid value given to [data-scroll-to] attribute');
      return;
    }

    const parentSelector = target.dataset.scrollParent;
    const parent = document.querySelector(parentSelector);
    let offset = 0;

    if (parent) {
      offset = directions[direction] === directions.top ? parent.getBoundingClientRect().top : parent.getBoundingClientRect().bottom - window.innerHeight;
    } else {
      offset = directions[direction] === directions.end ? document.body.getBoundingClientRect().bottom : 0;
    }
    scrollTo(offset);
  };

  document.body.addEventListener('click', (event) => {
    const { target } = event;
    if (target.dataset.scrollTo) {
      handleScroll(target);
    }
  });
};

export default scrollToDirection;
