import testElementsExistence from '../snippet/test-elements-existence';
import stickyElement from '../snippet/sticky-element';

/**
 * Collapsible module. Vertical collapsible based on jQuery. Is strictly bound to following css classes:
 *
 * {collapsible} - main container class
 * {collapsible-item} - item that holds collapsible content
 * {collapsible-item-toggle} - clickable element that expands accordion-item
 * {collapsible-item-body} - content that is collapsible
 * {collapsible-item-body-sidebar} - optional sidebar that becomes sticky above 1024px
 *
 * {collapsible-item-open} - class that marks expanded accordion item
 */

const collapsible = () => {
  const classes = {
    active: 'collapsible-item-open',
    container: 'collapsible',
    item: 'collapsible-item',
    toggle: 'collapsible-item-toggle',
    content: 'collapsible-item-body',
    sidebar: 'collapsible-item-body-sidebar',
    stickySidebar: 'collapsible-item-body-sidebar--active',
  };

  const selectors = {
    container: `.${classes.container}`,
    item: `.${classes.item}`,
    toggle: `.${classes.toggle}`,
    content: `.${classes.content}`,
    sidebar: `.${classes.sidebar}`,
  };

  const stickySidebarBreakpoint = 1024;
  const stickySidebarConfig = {
    offset_top: 30,
    parent: selectors.content,
    sticky_class: classes.stickySidebar,
  };
  const stickySidebar = stickyElement(stickySidebarConfig);

  const toggleContent = ($target) => {
    const $item = $target.closest(selectors.item);
    const $content = $item.find(selectors.content);

    $item.toggleClass(classes.active);
    $content.slideToggle();
  };

  const handleToggleEvents = (e) => {
    const $target = $(e.target);

    if (!$target.hasClass(classes.toggle)) {
      return;
    }

    toggleContent($target);
  };

  const closeAllItems = () => {
    $(selectors.item).each((index, item) => {
      const $item = $(item);

      if ($item.hasClass(classes.active)) {
        return;
      }

      $item.find(selectors.content).hide();
    });
  };

  const handleCollapsibleStickySidebars = ($sidebars, shouldStick) => {
    $sidebars.each((index, item) => {
      const $sidebar = $(item);

      if (shouldStick) {
        stickySidebar.stick($sidebar);
      } else {
        stickySidebar.unstick($sidebar);
      }
    });
  };

  const addEventListeners = ($container) => {
    $container.on('click', (e) => handleToggleEvents(e));

    const $sidebars = $container.find(selectors.sidebar);

    if ($sidebars.length) {
      $(window).on('load resize orientationchange', (e) => {
        if (e.currentTarget.innerWidth >= stickySidebarBreakpoint) {
          handleCollapsibleStickySidebars($sidebars, true);
          return;
        }

        handleCollapsibleStickySidebars($sidebars, false);
      });
    }
  };

  const init = () => {
    const $container = $(selectors.container);

    if (!$container.length) {
      return;
    }

    const selectorsNotFound = testElementsExistence($container[0], [
      selectors.toggle,
      selectors.content,
      selectors.item,
    ]);

    if (selectorsNotFound) {
      /* eslint-disable no-console */
      console.warn(`Please add following selectors to make the collapsible component work: ${selectorsNotFound}`);
      return;
    }

    closeAllItems();
    addEventListeners($container);
  };

  init();
};

export default collapsible;
