import 'slick-carousel';

const cardsBanner = () => {
  const selectors = {
    container: '.cards-banner',
    collection: '.cards-banner-collection',
  };

  $(selectors.container).each((index, item) => {
    const $cardsBanner = $(item);
    const $container = $cardsBanner.find(selectors.collection);
    const breakpoint = 767;
    const breakpointUnslick = 1023;

    const initialise = () => {
      const slickInitalized = $container.hasClass('slick-initialized');

      if (!slickInitalized) {
        $container.slick({
          autoplay: true,
          duration: 3000,
          arrows: true,
          dots: false,
          slidesToScroll: 1,
          slidesToShow: 1,
          mobileFirst: true,
          responsive: [
            {
              breakpoint,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: breakpointUnslick,
              settings: 'unslick',
            },
          ],
        });
      }
    };

    const showSliderContent = () => {
      $container.css('height', '100%');
      $container.css('opacity', 1);
    };

    $(window).on('load resize orientationchange', (e) => {
      if (e.currentTarget.innerWidth > breakpointUnslick) {
        showSliderContent();
      } else {
        $container.on('init', () => {
          showSliderContent();
        });
      }

      initialise();
    });
  });
};

export default cardsBanner;
