import 'sticky-kit/dist/sticky-kit.min';
/**
 * Script takes care of managing sticky positions and delivers wide browser support
 */
const stickyElement = (config) => {
  /**
   * @param {node} $element
   */
  const stick = ($element) => {
    $element.stick_in_parent(config);
  };

  /**
   * @param {node} $element
   */
  const unstick = ($element) => $element.trigger('sticky_kit:detach');

  return {
    stick,
    unstick,
  };
};

export default stickyElement;
