import 'slick-carousel';

const cardsRowCollection = () => {
  const selectors = {
    collection: '.cards-row-collection',
  };

  $(selectors.collection).each((index, item) => {
    const $container = $(item);
    const breakpoint = 767;

    const initialise = () => {
      const slickInitalized = $container.hasClass('slick-initialized');

      if (!slickInitalized) {
        $container.slick({
          autoplay: true,
          duration: 3000,
          arrows: true,
          dots: false,
          slidesToScroll: 1,
          slidesToShow: 1,
          mobileFirst: true,
          responsive: [
            {
              breakpoint,
              settings: 'unslick',
            },
          ],
        });
      }
    };

    const showSliderContent = () => {
      $container.css('height', '100%');
      $container.css('opacity', 1);
    };

    $(window).on('load resize orientationchange', (e) => {
      if (e.currentTarget.innerWidth > breakpoint) {
        showSliderContent();
      } else {
        $container.on('init', () => {
          showSliderContent();
        });
      }

      initialise();
    });
  });
};

export default cardsRowCollection;
