import testElementsExistence from '../snippet/test-elements-existence';

/**
 * Careers module
 *
 * Is strictly bound to following classes
 * {career-offer} the career item
 * {career-offer-show-more} the career toggle
 * {career-offer-body} - content that is collapsable
 */
const careers = () => {
  const classes = {
    item: 'career-offer',
    toggle: 'career-offer-show-more',
    content: 'career-offer-body',
    open: 'career-offer--open',
  };

  const selectors = {
    item: `.${classes.item}`,
    toggle: `.${classes.toggle}`,
    content: `.${classes.content}`,
  };

  const buttonStateText = {
    open: 'Show more',
    close: 'Show less',
  };

  const buttonStateClasses = {
    open: ['btn', 'btn-lg'],
    close: ['btn', 'btn-lg', 'btn-outline'],
  };

  const animationDuration = 250;

  const manageButtonStyle = ($button, isOpen) => {
    if (isOpen) {
      $button.removeClass(buttonStateClasses.close);
      $button.addClass(buttonStateClasses.open);
      return;
    }

    $button.removeClass(buttonStateClasses.open);
    $button.addClass(buttonStateClasses.close);
  };

  const toggle = ($item, isOpen) => {
    const buttonText = isOpen ? buttonStateText.open : buttonStateText.close;
    const $toggle = $item.find(selectors.toggle);
    const $content = $item.find(selectors.content);

    manageButtonStyle($toggle, isOpen);

    if (!isOpen) {
      $content.slideDown(animationDuration);
      $toggle.text(buttonText);
      $item.addClass(classes.open);
      return;
    }

    $content.slideUp(animationDuration);
    $toggle.text(buttonText);
    $item.removeClass(classes.open);
  };

  const manageOpenState = ($item, revertOpenState) => {
    const isOpen = revertOpenState ? !$item.hasClass(classes.open) : $item.hasClass(classes.open);
    toggle($item, isOpen);
  };

  const addEventListener = ($item) => {
    const $toggle = $item.find(selectors.toggle);
    $toggle.on('click', () => manageOpenState($item));
  };

  const init = ($item) => {
    const notFound = testElementsExistence($item[0], [
      selectors.toggle,
      selectors.content,
    ]);

    if (notFound) {
      /* eslint-disable no-console */
      console.warn(`Please add following selectors to make the career component work: ${notFound}`);
      return;
    }

    addEventListener($item);
    manageOpenState($item, true);
  };

  $(selectors.item).each((index, item) => init($(item)));
};

export default careers;
