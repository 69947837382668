import testElementsExistence from '../snippet/test-elements-existence';
import scrollTo from '../snippet/scrollTo';

/**
 * Accordion module, vertical behaviour until 1024px resolution met. Is strictly bound to following css classes:
 *
 * {accordion} - main container class
 * {accordion-item} - item that holds expandable content
 * {accordion-item-toggle} - clickable element that expands accordion-item
 * {accordion-item-body} - content that is expandable
 *
 * {accordion-item-open} - class that marks expanded accordion item
 */

const accordion = () => {
  const classes = {
    active: 'accordion-item-open',
    closing: 'accordion-item-closing',
    container: 'accordion',
    item: 'accordion-item',
    toggle: 'accordion-item-header',
    content: 'accordion-item-body',
  };

  const selectors = {
    container: `.${classes.container}`,
    item: `.${classes.item}`,
    toggle: `.${classes.toggle}`,
    content: `.${classes.content}`,
  };

  const animationDuration = 250;
  const breakpointSwitch = 1024;

  const orientation = {
    vertical: 'vertical',
    horizontal: 'horizontal',
  };

  const init = ($container, isOrientationHorizontal, orientationInitialised) => {
    const isVertical = orientationInitialised === orientation.vertical;

    const openItem = ($item) => {
      if (isVertical) {
        const $content = $item.find(selectors.content);
        $item.addClass(classes.active);
        $content.slideDown(animationDuration * 1.2);

        setTimeout(() => {
          scrollTo($item, 25, 250);
        }, animationDuration * 2);

        return;
      }

      setTimeout(() => {
        $item.addClass(classes.active);
      }, animationDuration);
    };

    const closeItem = ($item) => {
      if (isVertical) {
        const $content = $item.find(selectors.content);
        $content.slideUp(animationDuration * 1.2);
        $item.removeClass(classes.active);
        return;
      }

      $item.addClass(classes.closing);
      $item.removeClass(classes.active);
      setTimeout(() => {
        $item.removeClass(classes.closing);
      }, animationDuration * 2);
    };

    const hideAllActiveItems = () => {
      $container.find(selectors.item).each((index, item) => closeItem($(item)));
    };

    const runCloseElementsCheckOnInit = () => {
      $container.find(selectors.item).each((index, item) => {
        const $item = $(item);
        if ($item.hasClass(classes.active)) {
          return;
        }

        closeItem($item);
      });
    };

    const handleAccordionToggle = (e) => {
      const $target = $(e.target);
      const $toggler = $target.parents(selectors.toggle).length ? $target.parents(selectors.toggle) : $target;
      const $item = $toggler.closest(selectors.item);

      if ($item.hasClass(classes.active)) {
        return;
      }

      hideAllActiveItems($container);
      openItem($item);
    };

    runCloseElementsCheckOnInit();
    $container.on('click', (e) => handleAccordionToggle(e));
  };

  $(selectors.container).each((index, container) => {
    let isOrientationHorizontal = null;
    let orientationInitialised = '';
    let currentOrientation = '';
    const $container = $(container);
    const notFound = testElementsExistence($container[0], [
      selectors.toggle,
      selectors.content,
      selectors.item,
    ]);

    if (notFound) {
      /* eslint-disable no-console */
      console.warn(`Please add following selectors to make the collapsible component work: ${notFound}`);
      return;
    }

    $(window).on('load resize change', (e) => {
      isOrientationHorizontal = e.currentTarget.innerWidth >= breakpointSwitch;
      currentOrientation = isOrientationHorizontal ? orientation.horizontal : orientation.vertical;
      if (currentOrientation === orientationInitialised) {
        return;
      }
      init($container, isOrientationHorizontal, currentOrientation);
      orientationInitialised = currentOrientation;
    });
  });
};

export default accordion;
