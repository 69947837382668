const video = () => {
  function togglePlayState() {
    if (this.paused) {
      this.play();
    } else {
      this.pause();
    }
  }

  $('video').on('click', togglePlayState);
};

export default video;
