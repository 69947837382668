import testElementsExistence from './test-elements-existence';

/**
 * Snippet to show or hide content on given clickable element.
 * Add those classes to html markup
 *
 * {js--expandable-container} - container that holds all content
 * {js--expandable} - item that contents will expand or collapse
 * {js--expandable-toggle} - a target that will hide or show content after click
 * {js--expandable-content} - content that will be affected by the script
 */

const expandable = () => {
  const classes = {
    container: 'js--expandable-container',
    item: 'js--expandable',
    toggle: 'js--expandable-toggle',
    content: 'js--expandable-content',
    expanded: 'is-expanded',
  };
  const selectors = {
    container: `.${classes.container}`,
    item: `.${classes.item}`,
    toggle: `.${classes.toggle}`,
    content: `.${classes.content}`,
  };

  const animationDuration = 250;
  const $container = $(selectors.container);

  const hideAllContents = () => {
    $container.find(selectors.item).each((index, item) => {
      const $item = $(item);
      const $content = $item.find(selectors.content);

      $content.slideUp(animationDuration);
      $item.removeClass(classes.expanded);
    });
  };

  const showCurrentContent = ($item) => {
    const $content = $item.find(selectors.content);

    if ($item.hasClass(classes.expanded)) {
      $content.slideUp(animationDuration);
      $item.removeClass(classes.expanded);
      return;
    }

    hideAllContents();
    $item.addClass(classes.expanded);
    $content.slideDown(animationDuration);
  };

  const addEventListeners = () => {
    $container.on('click', (evt) => {
      const $toggler = $(evt.target);
      console.log($toggler);

      if ($toggler.hasClass(classes.toggle)) {
        const $item = $toggler.closest(selectors.item);
        showCurrentContent($item);
      }
    });

    $(document.body).on('click', (evt) => {
      const $toggler = $(evt.target);

      if (!$toggler.hasClass(classes.toggle)) {
        hideAllContents();
      }
    });
  };

  const init = () => {
    if (!$container.length) {
      return;
    }

    const notFound = testElementsExistence($container[0], [
      selectors.toggle,
      selectors.content,
      selectors.item,
    ]);

    if (notFound) {
      /* eslint-disable no-console */
      console.warn(`Please add following selectors to make the collapsible component work: ${notFound}`);
      return;
    }

    $(selectors.item).each((index, content) => {
      const $item = $(content);
      if ($item.hasClass(classes.expanded)) {
        return;
      }
      const $content = $item.find(selectors.content);
      $content.hide();
    });

    addEventListeners();
  };

  init();
};

export default expandable;
