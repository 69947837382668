/* eslint-disable */
import 'slick-carousel';

const isJson = (item) => {
  item = typeof item !== 'string' ? JSON.stringify(item) : item;

  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }

  return typeof item === 'object' && item !== null;
};

const sliders = document.querySelectorAll('.slider');
const initSlider = (slider) => {
  const slides = slider.querySelector('.slider-slides');
  const pagination = slider.querySelector('.slider-pagination');
  const dataConfig = slider.dataset.config;

  const initialConfig = {
    arrows: false,
    dots: true,
    appendDots: $(pagination),
  };

  let config = { initialConfig };

  if (isJson(dataConfig)) {
    config = { ...initialConfig, ...JSON.parse(dataConfig) };
  }

  $(slides).slick(config);
};
const init = () => {
  Array.from(sliders).forEach((s) => initSlider(s));
};

export default init;
