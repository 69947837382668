/**
 * Snippet that checks if element was found in given container
 *
 * @param {node} container - node that should be verified against given selectors
 * @param {Array<string>} selectors - selectors to run test against
 *
 * @return {string} - returns string of concatenated elements if not found
 * @return {boolean} - returns false if all elements exist
 */
const testElementsExistence = (container, selectors) => {
  const notFoundSelectors = [];
  selectors.forEach((selector) => {
    if (!container.querySelector(selector)) {
      notFoundSelectors.push(selector);
    }
  });

  return notFoundSelectors.length ? notFoundSelectors.join(', ') : false;
};

export default testElementsExistence;
