import findParent from '../snippet/find-parent';

const galleryAccordion = () => {
  const classes = {
    container: 'gallery-accordion',
    item: 'gallery-accordion-item',
    active: 'is-active',
  };

  const selectors = {
    container: `.${classes.container}`,
    item: `.${classes.item}`,
  };

  const showFirstByDefault = ($container) => {
    const $items = $container.find(selectors.item);
    $items.removeClass(classes.active);
    $items.first().addClass(classes.active);
  };

  const closeAllElements = ($container) => {
    const $items = $container.find(selectors.item);
    $items.removeClass(classes.active);
  };

  const manageItemsState = ($container, e) => {
    const $target = $(e.target);
    const $item = findParent($target, selectors.item);

    if ($item.hasClass(classes.active)) {
      return;
    }

    closeAllElements($container);
    $item.addClass(classes.active);
  };

  const init = ($container) => {
    $container.on('mouseover', (e) => manageItemsState($container, e));
    $container.on('mouseleave', () => showFirstByDefault($container));
  };

  $(selectors.container).each((index, container) => init($(container)));
};

export default galleryAccordion;
