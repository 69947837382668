/* eslint-disable no-param-reassign, no-shadow */
const toogler = () => {
  const defaultToggleClass = 'toggled';

  const isJson = (item) => {
    item = typeof item !== 'string'
      ? JSON.stringify(item)
      : item;

    try {
      item = JSON.parse(item);
    } catch (e) {
      return false;
    }

    return typeof item === 'object' && item !== null;
  };

  const doToggle = (targetSelector, toggleClass) => {
    const targets = document.querySelectorAll(targetSelector);

    for (let i = 0; i < targets.length; i += 1) {
      targets[i].classList.toggle(toggleClass);
    }
  };

  const toggle = (toggler) => {
    const { toggle } = toggler.dataset;
    if (isJson(toggle)) {
      const toggleConfig = JSON.parse(toggle);
      Object.keys(toggleConfig).forEach((targetSelector) => {
        const toggleClass = toggleConfig[targetSelector] || defaultToggleClass;
        doToggle(targetSelector, toggleClass);
      });
    } else {
      const toggleClass = toggler.dataset.toggleClass || defaultToggleClass;

      doToggle(toggle, toggleClass);
    }
  };

  document.body.addEventListener('click', (event) => {
    const { target } = event;
    if (target.dataset.toggle) {
      toggle(target);
    }
  });
};

export default toogler;
