/* snippets */
import toogler from './snippet/toggler';
import searchbarToggler from './snippet/searchbar-toggler';
import expandable from './snippet/expandable';
import formInputEvents from './snippet/form-input-events';
import slider from './snippet/slider';
import select2 from './snippet/select2';
import datepicker from './snippet/datepicker';
import counter from './snippet/counter';
import scrollToDirection from './snippet/scroll-to-direction';
import video from './snippet/video';

/* modules */
import showCase from './modules/show-case';
import cardsBanner from './modules/cards-banner';
import accordion from './modules/accordion';
import collapsible from './modules/collapsible';
import tabs from './modules/tabs';
import contextNav from './modules/context-nav';
import cardsRowCollection from './modules/cards-row-collection';
import textCardSlider from './modules/text-card-slider';
import galleryAccordion from './modules/gallery-accordion';
import careers from './modules/careers';
import header from './modules/header';
import modal from './modules/modal';

class App {
  constructor() {
    toogler();
    searchbarToggler();
    expandable();
    showCase();
    cardsBanner();
    formInputEvents();
    accordion();
    collapsible();
    slider();
    tabs();
    select2();
    datepicker();
    contextNav();
    counter();
    cardsRowCollection();
    textCardSlider();
    scrollToDirection();
    video();
    galleryAccordion();
    careers();
    header();
    modal();
  }
}

window.addEventListener('DOMContentLoaded', () => new App());
