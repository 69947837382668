/**
 * Modal component, available in global Window object
 *
 * Shares simple interface to work with
 * {open}
 * {destroy}
 * {close}
 */
const myModal = (selector) => {
  const classes = {
    modal: 'modal',
    openClass: 'modal--open',
    closeButton: 'modal-close',
    body: 'modal-body',
  };
  const $modalContainer = $(selector);

  const open = () => {
    $modalContainer.addClass(classes.openClass);
    $('body').addClass(classes.openClass);
  };

  const close = () => {
    $modalContainer.removeClass(classes.openClass);
    $('body').removeClass(classes.openClass);
  };

  const destroy = () => {
    $modalContainer.remove();
  };

  const createModalCloseButton = () => {
    if ($modalContainer.find(`.${classes.closeButton}`).length) {
      return;
    }

    const $button = $('<button></button>').addClass(classes.closeButton);
    $button.on('click', close);
    $modalContainer.find(`.${classes.body}`).append($button);
  };

  const onBackDropClick = () => {
    $modalContainer.on('click', (e) => {
      const $target = $(e.target);
      if ($target.hasClass(classes.modal)) {
        close();
      }
    });
  };

  const init = () => {
    createModalCloseButton();
    onBackDropClick();
  };

  init();

  return {
    open, close, destroy,
  };
};

const modalInit = () => {
  window.modal = myModal;
};

modalInit();

export default myModal;
