/**
 * Switchable tabs module.
 *
 * {tabs-nav} - tabs navigation
 * {tabs-nav-item} - tabs nav link
 * {tabs-content} - tabs with content
 *
 *
 * tab-nav-item link should point to id in {data-tab-id}
 *
 * Example
 * <nav class="tabs-nav">
 *  <a class="tabs-nav-item" href="#press-releases">Press Releases</a>
 * </nav>
 *
 * <div class="tabs-content">
 *   <div class="tabs-content-tab" data-tab-id="press-releases" />
 * </div>
 */
/* eslint-disable */
const tabs = () => {
  const classes = {
    nav: 'tabs-nav',
    navItem: 'tabs-nav-item',
    content: 'tabs-content',
    contentTab: 'tabs-content-tab',
    active: 'active',
    loading: 'is-loading',
  };

  const selectors = {
    nav: `.${classes.nav}`,
    navItem: `.${classes.navItem}`,
    content: `.${classes.content}`,
    contentTab: `.${classes.contentTab}`,
  };

  const attributes = {
    tabItem: 'tab',
    tabItemContent: 'tab-id',
  };

  const changeBehaviourOnBreakpoint = 768;

  const closeAllContentTabs = ($contentTabs) => {
    $contentTabs.removeClass(classes.active);
  };

  const showBoundContentTab = ($targetedTab) => {
    $targetedTab.addClass(classes.active);
  };

  const scrollToActiveTab = ($target) => {
    $('html, body').delay(1000).animate({ scrollTop: $target.offset().top - 60 }, 500);
  };

  const toggleTabContents = ($item, $boundContent) => {
    const targetName = false;
    try{
        const targetName = $item[0].hash.split('#')[1];
    }catch (error){}
    if (!targetName) {
      /* eslint-disable no-console */
      console.warn(`href attribute in ${$item[0]} element is not correct, don't forget to use #`);
      return;
    }
    const $targetedTab = $boundContent.find(`[data-${attributes.tabItemContent}="${targetName}"]`);
    const $contentTabs = $boundContent.find(selectors.contentTab);

    closeAllContentTabs($contentTabs);
    showBoundContentTab($targetedTab);
  };

  const toggleTabs = ($item, $tab) => {
    const $items = $tab.find(selectors.navItem);
    $items.removeClass(classes.active);
    $item.addClass(classes.active);
  };

  const centerCurrentTab = ($tab, $activeTab) => {
    const leftOffset = $activeTab.offset().left - $activeTab.width();
    $tab.animate({
      scrollLeft: leftOffset,
    }, 250);
  };

  const runCurrentTabOnLoad = ($tab, $boundContent) => {
    const tabId = window.location.hash;

    if (!tabId) {
      const $firstTab = $tab.find(selectors.navItem).first();
      toggleTabContents($firstTab, $boundContent);
      toggleTabs($firstTab, $tab);
      return;
    }

    const $tabItems = $tab.find(selectors.navItem);
    const $activeTab = $tabItems.filter((index, tabLink) => tabLink.hash === tabId);

    if (!$activeTab.length) {
      return;
    }

    toggleTabContents($activeTab, $boundContent);
    toggleTabs($activeTab, $tab);
    scrollToActiveTab($activeTab);

    $(window).on('load', (e) => {
      if (e.currentTarget.innerWidth >= changeBehaviourOnBreakpoint) {
        return;
      }

      centerCurrentTab($tab, $activeTab);
    });
  };

  const init = ($tab) => {
    const $boundContent = $(selectors.content);
    if (!$boundContent.length) {
      /* eslint-disable no-console */
      console.warn(`Please add ${classes.content} class to make the component work.`);
      return;
    }

    $tab.on('click', (e) => {
      const $target = $(e.target);
      const $item = $target.parents(selectors.navItem).length ? $target.parents(selectors.navItem) : $target;

      toggleTabContents($item, $boundContent);
      toggleTabs($item, $tab);
    });

    runCurrentTabOnLoad($tab, $boundContent);
  };

  $(selectors.nav).each((index, tab) => init($(tab)));
};

export default tabs;
