import 'waypoints/lib/jquery.waypoints.min';

/**
 * Counter element, mounts on {data-counter} which is also a required parameter
 * add following parameters to customize counter
 * {data-counter} - required parameter to count to
 * {data-counter-start} - defaults to 0
 * {data-counter-duration} - defaults to 2500
 */
const counter = () => {
  const attributes = {
    counter: 'counter',
    start: 'counterStart',
    duration: 'counterDuration',
  };

  const selectors = {
    counter: '[data-counter]',
    start: '[data-counter-start]',
    duration: '[data-counter-duration]',
  };

  const init = ($target) => {
    let isCounted = false;
    const end = +$target.data(attributes.counter);
    let start = +$target.data(attributes.start) || 0;
    const duration = +$target.data(attributes.duration) || 2500;

    const range = end - start;
    const increment = end > start ? 1 : -1;
    const step = Math.abs(Math.floor(duration / range));

    /* eslint-disable no-undef, no-new */
    new Waypoint({
      element: $target[0],
      handler: () => {
        if (isCounted) {
          return;
        }
        const timer = setInterval(() => {
          start += increment;
          $target.text(start);

          if (start === end) {
            clearInterval(timer);
          }
        }, step);
        isCounted = true;
      },
      offset: '100%',
    });
  };

  $(selectors.counter).each((index, target) => init($(target)));
};

export default counter;
