import 'slick-carousel';

const textCardSlider = () => {
  const selectors = {
    container: '.text-card-grid-slider',
    collection: '.text-card-grid-collection',
  };

  $(selectors.container).each((index, collection) => {
    const $container = $(collection);
    const $collection = $container.find(selectors.collection);
    const breakpointUnslick = 1024;

    const initialise = () => {
      const slickInitalized = $collection.hasClass('slick-initialized');

      if (!slickInitalized) {
        $collection.slick({
          autoplay: true,
          duration: 3000,
          arrows: true,
          dots: false,
          slidesToScroll: 1,
          slidesToShow: 1,
          mobileFirst: true,
          responsive: [
            {
              breakpoint: breakpointUnslick,
              settings: 'unslick',
            },
          ],
        });
      }
    };

    $(window).on('load resize orientationchange', () => {
      initialise();
    });
  });
};

export default textCardSlider;
