import 'slick-carousel';
import testElementsExistence from '../snippet/test-elements-existence';

/**
 * ShowCase module, slider until 1024px met. Is strictly bound to following css classes:
 *
 * {show-case} - main container class
 * {show-case-collection} - collection of sci items - slider initialisation container
 * {sci} - show case item
 * {sci-extra} - content that is expandable
 * {sci-overview} - clickable element
 *
 * {sci-open} - class that marks expanded show case item
 */
const showCase = () => {
  const classes = {
    container: 'show-case',
    collection: 'show-case-collection',
    item: 'sci',
    content: 'sci-extra',
    toggle: 'sci-overview',
    open: 'sci-open',
    sliderInitialised: 'slick-initialized',
  };

  const selectors = {
    container: `.${classes.container}`,
    collection: `.${classes.collection}`,
    item: `.${classes.item}`,
    content: `.${classes.content}`,
    toggle: `.${classes.toggle}`,
  };

  const events = {
    pauseSlider: 'slickPause',
    playSlider: 'slickPlay',
  };

  const closeAllItems = ($container) => {
    $container.find(selectors.item).each((index, item) => {
      const $item = $(item);
      const $content = $item.find(selectors.content);

      $content.slideUp();
      $item.removeClass(classes.open);
    });
  };

  const pauseSlider = ($slider) => {
    $slider.trigger(events.pauseSlider);
  };

  const playSlider = ($slider) => {
    $slider.trigger(events.playSlider);
  };

  const openItem = ($item) => {
    const $content = $item.find(selectors.content);

    $content.slideDown();
    $item.addClass(classes.open);
  };

  const closeItem = ($item) => {
    const $content = $item.find(selectors.content);

    $content.slideUp();
    $item.removeClass(classes.open);
  };

  const getItemToggler = (e) => {
    const $target = $(e.target);
    return $target.parents(selectors.toggle).length ? $target.parents(selectors.toggle) : $target;
  };

  const initSlider = ($collection, callback) => {
    $collection.on('init', (evt, slick) => {
      if (callback) {
        callback(evt, slick);
      }
    });

    $collection.on('beforeChange', () => {
      closeAllItems($collection);
    });

    const initialise = () => {
      const slickInitalized = $collection.hasClass(classes.sliderInitialised);

      if (!slickInitalized) {
        const sliderDestroyBreakpoint = 1023;

        $collection.slick({
          autoplay: true,
          duration: 3000,
          arrows: true,
          dots: false,
          slidesToScroll: 1,
          slidesToShow: 1,
          mobileFirst: true,
          responsive: [
            {
              breakpoint: sliderDestroyBreakpoint,
              settings: 'unslick',
            },
          ],
        });

        $collection.bind(events.pauseSlider, () => $collection.slick(events.pauseSlider));
        $collection.bind(events.playSlider, () => $collection.slick(events.playSlider));
      }
    };

    $(window).on('load resize orientationchange', () => {
      initialise();
    });
  };

  const testForCorrectStructure = ($item) => {
    const notFound = testElementsExistence($item[0], [
      selectors.toggle,
      selectors.content,
    ]);

    if (notFound) {
      /* eslint-disable no-console */
      console.warn(`Please add following selectors to ${$item[0]} to make the expandable item work: ${notFound}`);
    }
  };

  const initExpandableItems = ($collection) => {
    const $items = $collection.find(selectors.item);

    if (!$items.length) {
      return;
    }

    $items.each((index, item) => {
      testForCorrectStructure($(item));
    });

    $collection.on('click', (e) => {
      const $toggle = getItemToggler(e);
      const $item = $toggle.parent(selectors.item);

      if (!$toggle.hasClass(classes.toggle)) {
        return;
      }

      if ($item.hasClass(classes.open)) {
        closeItem($item);
        playSlider($collection);
        return;
      }

      closeAllItems($collection);
      openItem($item);
      pauseSlider($collection);
    });
  };

  const addEventListeners = ($container) => {
    $(document.body).on('click', (e) => {
      const $toggler = getItemToggler(e);

      if (!$toggler.hasClass(classes.toggle)) {
        closeAllItems($container);
      }
    });
  };

  const init = ($container) => {
    const $collection = $container.find(selectors.collection);

    if (!$collection.length) {
      /* eslint-disable no-console */
      console.warn(`Please add following element to the show case: ${classes.collection}`);
      return;
    }

    initSlider($collection);
    initExpandableItems($collection);
    addEventListeners($container);
  };

  $(selectors.container).each((index, container) => {
    init($(container));
  });
};

export default showCase;
