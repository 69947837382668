/**
 * handles sticky header
 */
const header = () => {
  const $navContainer = $('.navbar-container');
  const stickyClass = 'is-stuck';

  function handleStickyBehavior() {
    if ($(this).scrollTop() > 1) {
      $navContainer.addClass(stickyClass);
    } else {
      $navContainer.removeClass(stickyClass);
    }
  }

  $(window).on('scroll', handleStickyBehavior);
};

export default header;
